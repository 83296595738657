'use strict';

if ($$UPDATE_LEVEL$$ !== "RELEASE" || $$BUILD_INFO$$.mode === "development") {
    window.console = function () {
        // catching all errors
        window.onerror = function (err, file, line, col, e) {
            var errorStr = 'An error occurred in file ' + file + ':' + line + ':' + col;

            if (e) {
                if (e instanceof Error) {
                    errorStr = errorStr + '\n' + e.stack;
                } else if (typeof e.toString === "function") {
                    errorStr = errorStr + '\n' + e.toString();
                }
            }

            debugConsole.error(errorStr);
            return true;
        };

        let console = window.console || {},
            history = [],
            updateFn = null,
            historySize = 0,
            exceedMsg = "... history quota exceeded, please share the log or further logging",
            isiOS = /iP(ad|hone|od)/.test(navigator.userAgent);

        function getSpaces(numSpaces) {
            var result = "";

            for (var i = numSpaces; i > 0; i--) {
                result += " ";
            }

            return result;
        }

        function writeLog(level) {
            // Removes the first argument
            [].shift.call(arguments);
            var string = [].join.call(Array.from(arguments).map(function (arg) {
                if (arg instanceof Object) {
                    try {
                        return Flatted.stringify(arg);
                    } catch (e) {
                        return "[Unstringifyable Object]";
                    }
                } else {
                    return arg;
                }
            }), " ");
            var args = addDate.apply(this, arguments);
            var stackSize = new Error().stack.split("\n").length;
            args[1] = getSpaces(stackSize * 2) + args[1];
            console[level].apply(this, args);

            if (isiOS && historySize > 1000000) {
                // Why 1000k? That's approximately the char count of a 1MB file
                clearLogs();
                history.push({
                    level: "info",
                    time: moment().format('HH:mm:ss.SSS:'),
                    string: exceedMsg
                });
                historySize += exceedMsg.length;
            }

            history.push({
                level: level,
                time: args[0],
                string: string
            });
            historySize += string.length;
            updateFn && updateFn(args[0], string, level);
        }

        var addDate = function addDate() {
            if (moment) {
                [].unshift.call(arguments, moment().format('HH:mm:ss.SSS:'));
            }

            return arguments;
        };

        var getLogs = function getLogs() {
            return history;
        };

        var clearLogs = function clearLogs() {
            history = [];
            historySize = 0;
        };

        var registerForLogs = function registerForLogs(fn) {
            updateFn = fn;
        };

        var unregisterFromLogs = function unregisterFromLogs() {
            updateFn = null;
        };

        var lxConsole = {
            original: console,
            history: history,
            writeLog: writeLog,
            getLogs: getLogs,
            clearLogs: clearLogs,
            registerForLogs: registerForLogs,
            unregisterFromLogs: unregisterFromLogs
        }; // if WebKitDeveloperExtras aren't set, window.console isn't available
        // for this situation, cordova will set the console object in the native part of the library
        // therefore check if the normal JS methods are available

        if (console.time) {
            lxConsole.time = console.time.bind(console);
            lxConsole.timeEnd = console.timeEnd.bind(console);
        }

        if (console.group) {
            lxConsole.group = console.group.bind(console);
            lxConsole.groupEnd = console.groupEnd.bind(console);
        }

        return new Proxy(lxConsole, {
            /**
             * Dynamically check if the property exists, if it exists we directly return the value/function,
             * if not we assume its assume its a console.X() call, where x is the logging scope.
             * That way we never have an exception in a library about a missing log function, we fall back to the "log" scope
             * @param target
             * @param name The key used to get the value from the object (target). Can be seen as a subscript
             * @return {*}
             */
            get(target, name) {
                if (name in target) {
                    return target[name];
                } else {
                    try {
                        return target.writeLog.bind(console, name);
                    } catch (e) {
                        return target.writeLog.bind(console, "log");
                    }
                }
            }

        });
    }(); // store these refs because someone may also override the console like we did. (eg. log plugin)


    window.originalConsole = window.console.original;
    window.debugConsole = window.console;
    window.debugConsole.info("BuildInfo:");
    window.debugConsole.info(JSON.stringify(BuildInfo, null, 4));
}

